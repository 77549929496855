<template>
  <div>
    <div class="w-100">
      <div class="container-fluid tab-panel mt-5 p-0">
        <div class="card w-100">
          <div class="card-body row">
            <div class="col-12 fit-content">
              <div
                class="card-header font-large ProximaNovaBold"
                style="
                          padding: 1.5rem 1.5rem;
                          background: transparent;
                          border: 0px !important;
                        "
              >
                {{ text }}
              </div>
              <button
                class="hide-breakdown btn-default float-right"
                @click="toggleBreakdown"
              >
                <i
                  class="fas fa-eye"
                  style="align-items: left"
                  v-if="breakdown"
                ></i>
                <img
                  src="@/assets/Icons/hide-password.svg"
                  alt=""
                  v-if="!breakdown"
                />
                Breakdown
              </button>
              <w-columnchart
                :chartData="chartData"
                @columnClick="clickedExpand($event)"
              />
              <div
                v-if="breakdown && showBreakdown"
                class="border_top_divider"
                :style="{
                  '--left': findIndexPosition + '%',
                }"
              >
                <div
                  class="card-header font-large ProximaNovaBold content-center"
                  style="
                            justify-content: space-between;
                            background: transparent;
                            border: 0px !important;
                          "
                >
                  <div>{{ `${title} (%)` }}</div>

                  <div
                                  v-if="showBreakdown"

                    class="
                              dropdown-breakdown
                              float-right
                              position-relative
                            "
                  >
                    <w-dropdown
                      :options="dropdownList"
                      :placeHolder="'Select'"
                      class="mr-3"
                      dropdownWidth="100%"
                      bgColor="#fff"
                      labelColor="#727988"
                      boxShadow="box-shadow: 0 0 8px 0 #c9ced5;"
                      :selectedOption="selectedOption"
                      @input="selectedDropdown($event)"
                    ></w-dropdown>
                  </div>
                </div>
                <w-columnchart :chartData="expandData" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import Dropdown from "@/widgets/Dropdown.vue";
export default {
  name: "w-chart-data",
  components: {
    "w-dropdown": Dropdown,
    "w-columnchart": ColumnChart,
  },
  props: {
    expandData: {
      type: Object,
      default() {},
    },
    title: {
      type: String,
      default:"",
    },
    dropdownList: {
      type: Array,
      default: () => [],
    },
    selectedOption: {
      type: Object,
      default() {},
    },
    chartData: {
      type: Object,
      default() {},
    },
    text: {
      type: String,
      default: "Test",
    },
    showBreakdown:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selecteOption: {},
      breakdown: false,
      valueAdded: 0,
    };
  },
  computed:{
    findIndexPosition(){
      var valueAdded = 0;
      let ind = this.chartData.xAxis.categories.findIndex(
        (obj) => obj === this.title
      );
      var slotedPosition = 100 / this.chartData.xAxis.categories.length;
      var middle = slotedPosition / 2;
      if (ind > 0) {
        for (var i = 0; i < ind; i++) {
          valueAdded += slotedPosition;
          if (i === ind - 1) {
            valueAdded += middle;
          }
        }
      } else {
        valueAdded = slotedPosition / 2;
      }
      return valueAdded
    }
  },
  methods: {
    // findIndexPosition() {
    //   this.valueAdded = 0;
    //   let ind = this.chartData.xAxis.categories.findIndex(
    //     (obj) => obj === this.title
    //   );
    //   var slotedPosition = 100 / this.chartData.xAxis.categories.length;
    //   var middle = slotedPosition / 2;
    //   if (ind > 0) {
    //     for (var i = 0; i < ind; i++) {
    //       this.valueAdded += slotedPosition;
    //       if (i === ind - 1) {
    //         this.valueAdded += middle;
    //       }
    //     }
    //   } else {
    //     this.valueAdded = slotedPosition / 2;
    //   }
    // },
    selectedDropdown(e) {
      this.selecteOption = e;
    //   this.findIndexPosition();
      this.$emit("selectedOption", e);
    },
    clickedExpand(data) {
      var title = {
        text: data,
        id: data,
        iconHTML: '<i class="fab fa-' + data.toLowerCase() + "></i>",
      };
      this.breakdown = true;
      this.selecteOption = title;
      
      this.$emit("chartClick", title);
    //   this.findIndexPosition();
    },
    toggleBreakdown() {
    //   this.findIndexPosition()
      this.breakdown = !this.breakdown;
      this.selecteOption = this.dropdownList[0];
      this.$emit("chartClick", this.selecteOption);
    },
  },
};
</script>

<style scoped>
.card_body_padding {
  padding: 30px 24px !important;
}
.fit-content {
  height: fit-content;
}
.hide-breakdown {
  width: 150px;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background: #f3f3f3;
  font-size: 15px;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 45px;
}
.border_right {
  border-right: 1px solid #ccc;
}
.border_right::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: -11px;
  width: 21px;
  height: 20px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(134deg);
}
.border_top_divider {
  border-top: 1px solid #ccc;
}
.border_top_divider::after {
  content: "";
  display: block;
  position: absolute;
  top: 47.8%;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(224deg);
  left: var(--left);
}
.dropdown-breakdown {
  width: 210px;
  object-fit: contain;
  font-size: 15px;
  position: absolute;
  right: 15px;
}
.bottom_header {
  position: sticky;
  top: 100px;
  z-index: 8;
  background: #f2f5fa;
  box-shadow: rgb(233 235 239) 0px 5px 5px 0px;
}
</style>
