<template>
  <div>
    <div>
      <div class="page-wrapper chiller-theme" style="margin-top: 71px">
        <main class="page-content">
          <div class="container-fluid">
            <div class="d-flex justify-content-between">
              <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
                <!--<li>
                  <router-link to="/solutions/in/platform/models">
                    <span>Model</span>
                  </router-link>
                </li>-->
                <li>
                  <router-link to="/solutions/in/platform/allstats">
                    <span>Model Result</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/solutions/in/platform/attribution">
                    <span>Attribution</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/solutions/in/platform/stat">
                    <span>Sale Statistics</span>
                  </router-link>
                </li>
                <li class="ProximaNovaBold" v-if="activeTab === 'Causal Insights'">
                  Causal Insights
                </li>
                <li class="ProximaNovaBold" v-if="activeTab === 'Future Planning'">
                  Future Planning
                </li>
                <li class="ProximaNovaBold" v-if="activeTab === 'ROI Summary'">
                  ROI Summary
                </li>
              </ul>
              <div v-if="activeTab === 'Causal Insights'" class="d-flex">
                <div class="d-none" style="height: 40%">
                  <div class="w-100 pt-4">
                    <w-white-dropdown
                      :options="durationDropdown"
                      :placeHolder="'Select'"
                      :selectedOption="selectedDuration"
                      :labelText="'Duration'"
                      class="mr-3"
                      @input="selectDuration($event)"
                    ></w-white-dropdown>
                  </div>
                </div>
                <div class="" style="height: 40%">
                  <div class="w-100 pt-4">
                    <w-white-dropdown
                      :options="marketDropdown"
                      :placeHolder="'Select'"
                      :selectedOption="selectedMarket"
                      :labelText="'KPI'"
                      class="mr-3"
                      @input="selectMarket($event)"
                    ></w-white-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid mt-5">
            <w-tab :tabName="activeTab" :tabs="tabs" @activeTab="changeTab($event)">
            </w-tab>
            <div v-if="activeTab === 'Causal Insights'">
              <div class="row w-100 mx-0 mt-4 d-none">
                <div class="w-100 p-0">
                  <div class="container-fluid tab-panel p-0">
                    <div class="card w-100">
                      <div class="card-heading">Lifetime</div>
                      <div class="card-body p-0">
                        <div>
                          <w-chartoverview
                            :chartSummary="threeYearData"
                          ></w-chartoverview>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-100 p-0 d-none">
                  <div class="container-fluid tab-panel p-0">
                    <div class="card w-100">
                      <div class="card-heading">1 Jan 2020 to 15 Nov 2021</div>
                      <div class="card-body p-0">
                        <div>
                          <w-chartoverview :chartSummary="alltimeData"></w-chartoverview>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-100 p-0 d-none">
                  <div class="container-fluid tab-panel p-0">
                    <div class="card w-100">
                      <div class="card-heading">April 2021 to Nov 2021</div>
                      <div class="card-body p-0">
                        <div>
                          <w-chartoverview :chartSummary="salseStatics"></w-chartoverview>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <b-row class="no-gutters">
                  <b-col lg="6 pr-2 d-none">
                    <div class="card">
                      <ColumnChart :chartData="overAllData" />
                    </div>
                  </b-col>
                  <b-col lg="6 pl-2 d-none">
                    <div class="card">
                      <ColumnChart :chartData="externalInternalWiseData" />
                    </div>
                  </b-col>
                  <b-col lg="6 pr-2">
                    <div class="card">
                      <ColumnChart :chartData="regionWiseData" />
                    </div>
                  </b-col>
                  <b-col lg="6 pl-2">
                    <div class="card">
                      <ColumnChart :chartData="internalColumnData" />
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="col-12 col-lg-6 pl-0 d-none">
                <div class="card mt-5 p-5">
                  <div class="">
                    <ColumnChart :chartData="stackChartData" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 pr-0 d-none">
                <div class="card mt-5 p-5">
                  <div class="">
                    <ColumnChart :chartData="mediaChartData" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 pr-0 d-none">
                <div class="card mt-5 p-5">
                  <div class="">
                    <ColumnChart :chartData="quartlyAttribution" />
                  </div>
                </div>
              </div>
              <w-chart-data
                :expandData="attributionByChannelExpandData"
                :dropdownList="tvdropdown"
                :title="attributionDataTitle"
                :selectedOption="attributionTitle"
                :chartData="attributionChannelWise"
                :showBreakdown="showBreakdown"
                @chartClick="clickedAttribution($event)"
                @selectedOption="selectedAttributionDropdown($event)"
                :text="'Attribution (%)'"
                class="p-0 mb-5"
              >
              </w-chart-data>
              <w-chart-data
                :expandData="invsroiExpandData"
                :dropdownList="tvdropdown"
                :title="tvDataTitle"
                :selectedOption="tvTitle"
                :chartData="investRoiData"
                :showBreakdown="showBreakdown"
                @chartClick="clickedTv($event)"
                @selectedOption="selectedTvDropdown($event)"
                :text="'Investment vs Revenue  by Channel (#)'"
                class="p-0 d-none"
              >
              </w-chart-data>
              <!--<div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Content
                    :stripData="mediaData"
                    :title="'Media'"
                    :breakeDownShow="false"
                    :showBreakdownIcon="false"
                  />
                </b-container>
              </div>-->
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <TwoLevelStripBarChart
                    :stripData="twoLevelTVStripData.stripData"
                    :title="twoLevelTVStripData.title"
                    @getDataBreakdown="getTVStripBreakdown"
                    :secondStripData="secondTVStripData"
                    @getBarDataBreakdown="getTVBarDataBreakdown"
                    :barData="twoLevelTVBarData"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <TwoLevelStripBarChart
                    :showBreakdownIcon="false"
                    :stripData="twoLevelStripData.stripData"
                    :title="twoLevelStripData.title"
                    @getDataBreakdown="getStripBreakdown"
                    :secondStripData="secondStripData"
                    @getBarDataBreakdown="getBarDataBreakdown"
                    :barData="twoLevelBarData"
                  />
                </b-container>
              </div>

              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Digital
                    :data="digitalExpandData"
                    :stripData="digitalData"
                    @getDataBreakdown="getDigitalBreakdown"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Content
                    :stripData="printData"
                    :title="'Print'"
                    :breakeDownShow="false"
                    :showBreakdownIcon="false"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Content
                    :stripData="radioData"
                    :title="'Radio'"
                    :breakeDownShow="false"
                    :showBreakdownIcon="false"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Content
                    :stripData="oohData"
                    :title="'OOH'"
                    :breakeDownShow="false"
                    :showBreakdownIcon="false"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Content
                    :stripData="aggData"
                    :title="'Aggregators'"
                    :breakeDownShow="false"
                    :showBreakdownIcon="false"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <line-chart :boxshadow="true" :chartData="lineChartData" />
                </b-container>
              </div>
              <!--<div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :stripData="sponsorshipData"
                    :title="'Sponsorship'"
                    :breakeDownShow="false"
                    :showBreakdownIcon="false"
                  />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :barData="contentExpandData"
                    :stripData="contentData"
                    @getDataBreakdown="getContentBreakdown"
                  />
                </b-container>
              </div>-->
              <!--<div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :barData="billboardExpandData"
                    :title="'Billboard'"
                    :stripData="billboardData"
                    @getDataBreakdown="getBillboardBreakdown"
                  />
                </b-container>
              </div>-->
            </div>
            <w-summary v-if="activeTab === 'ROI Summary'"></w-summary>
            <w-future-planning v-if="activeTab === 'Future Planning'"></w-future-planning>
          </div>
          <!-- <page-loader v-if="count > 6"></page-loader> -->
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import Tab from "@/components/Solutions/Tab.vue";
// import PieChart from "@/components/Chart/PieChart.vue";
import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
// import MultipleDonutChart from "@/components/Chart/MultipleDonutChart.vue";
import ROISummary from "./ROI-Summary.vue";
import FuturePlanning from "@/pages/DEMO/AIMO-IN/FuturePlanning.vue";
import { AimoSolutionsServices } from "@/services/AimoSolutionsServices.js";
// import { AimoUnileverServices } from "@/services/AimoUnileverServices.js";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
const aimoSolutionsServices = new AimoSolutionsServices();
// const aimoSolutionsROIServices = new AimoUnileverServices()
// import PageLoader from "@/widgets/PageLoader.vue";
import TwoLevelStripBarChart from "@/components/Solutions/TwoLevelStripBarChart.vue";
import ChartOverview from "@/components/Summary/ChartOverview.vue";
import LineChart from "@/components/Chart/LineChart.vue";
import ChartWiseData from "@/components/Summary/NewChannelWiseData.vue";
export default {
  name: "Insights",
  components: {
    // PageLoader,
    LineChart,
    "w-summary": ROISummary,
    ColumnChart,
    "w-tab": Tab,
    "w-white-dropdown": WhiteDropdown,
    "w-future-planning": FuturePlanning,
    "w-chartoverview": ChartOverview,
    "w-chart-data": ChartWiseData,
    // PieChart,
    Digital,
    Content,
    TwoLevelStripBarChart,
    // MultipleDonutChart,
  },
  data() {
    return {
      subId: sessionStorage.getItem("subId"),
      attributionTitle: { id: "CampaignName", text: "CampaignName" },
      showBreakdown: false,
      tvTitle: {},
      tvDataTitle: "",
      attributionDataTitle: "",
      tvdropdown: [],
      secondStripData: [],
      secondTVStripData: [],
      lineChartData: {
        colors: ["#050505", "#18ffff"],
        series: [
          {
            data: [1, 2, 3, 3, 2, 1, 4, 5, 3, 7, 9, 10],
            marker: {
              symbol: "circle",
              radius: 5,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "TV Lag effect",
          },
        ],
        title: {
          text: "TV Lag effect",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        legend: {
          align: "right",
          verticalAlign: "top",
          y: -20,
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemMarginBottom: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        xAxis: {
          title: {
            text: "Weeks",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function () {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x).options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
      salseStatics: [
        {
          name: "Marketing ROI",
          value: "18.62x",
          className: "blue-pastel",
          image: "Marketing ROI.svg",
        },
        {
          name: "Investment",
          value: "103.7Cr",
          className: "green-pastel",
          image: "Investment.svg",
        },
        {
          name: "Media Impacted Revenue",
          value: "1931.85Cr",
          className: "pink-pastel",
          image: "ROI.svg",
        },
      ],
      alltimeData: [
        {
          name: "Marketing ROI",
          value: "16.85x",
          className: "blue-pastel",
          image: "Marketing ROI.svg",
        },
        {
          name: "Investment",
          value: "273.3Cr",
          className: "green-pastel",
          image: "Investment.svg",
        },
        {
          name: "Media Impacted Revenue",
          value: "4605.32Cr",
          className: "pink-pastel",
          image: "ROI.svg",
        },
      ],
      threeYearData: [
        // {
        //   name: "Marketing ROI",
        //   value: "18.31x",
        //   className: "blue-pastel",
        //   image: "Marketing ROI.svg",
        // },
        // {
        //   name: "Investment",
        //   value: "408.0Cr",
        //   className: "green-pastel",
        //   image: "Investment.svg",
        // },
        // {
        //   name: "Media Impacted Revenue ",
        //   value: "7474.0Cr",
        //   className: "pink-pastel",
        //   image: "ROI.svg",
        // },
      ],
      twoLevelStripData: {
        title: "Digital",
        stripData: [],
        secondStripData: [],
      },
      twoLevelTVStripData: {
        title: "Digital",
        stripData: [],
        secondStripData: [],
      },
      overAllData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Overall",
            data: [343010000, 79740000, 70020000],
          },
        ],
        title: {
          text: "Overall",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: ["Baseline", "External Factors", "Internal Factors"],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      externalInternalWiseData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Internal Vs External",
            data: [],
          },
        ],
        title: {
          text: "Internal vs External",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: ["Internal", "External"],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#F7FAFF",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      invsroiExpandData: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Revenue",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      attributionByChannelExpandData: {
        data: [
          { name: "", data: [] },
          // {
          //   name: "Revenue",
          //   data: [50, 30, 50, 50, 20, 50],
          // },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          enabled:false,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      investRoiData: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "Revenue",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      attributionChannelWise: {
        data: [
          {
            name:"",
            data: [],
          },
        ],
        xAxis: {
          categories: [],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {
          enabled: false,
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      regionWiseData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "",
            data: [],
          },
        ],
        title: {
          text: "Advertising vs Baseline (%)",
          // text: "External(21.02%)",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#F7FAFF",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
            dataLabels: {
              enabled: false,
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      internalColumnData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "",
            data: [],
          },
        ],
        title: {
          text: "Media (%)",
          // text: "Internal(78.98%)",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#F7FAFF",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      stackChartData: {
        //scrollableWidth: 1200,
        data: [],
        title: {
          text: "Marketing Attribution over a period of time",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#67e8ff",
          "#b5a9ff",
          "#81f89e",
          "#ffdd7c",
          "#ffa2a2",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        legend: {
          enabled: true,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            borderRadiusTopLeft: 0,
            borderRadiusTopRight: 0,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      mediaChartData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Media",
            data: [],
          },
        ],
        title: {
          text: "Media",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#F7FAFF",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      quartlyAttribution: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Attribution by Quarter ",
            data: [],
          },
        ],
        title: {
          text: "Attribution by Quarter ",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#F7FAFF",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      mediaData: [],
      pieChartColor: ["#ff4081", "#64ffda"],
      chart2Color: [
        "#6a1b9a",
        "#d81b60",
        "#1e88e5",
        "#a71313",
        "#5e35b1",
        "#00acc1",
        "#fb8c00",
        "#d81b60",
        "#64ffda",
      ],
      count: 0,
      breakDown: true,
      billboardData: [],
      contentBreakDown: false,
      activeTab: "Causal Insights",
      activeFrom: 1,
      selectedDuration: { text: "3 Month", id: "3" },
      selectedMarket: { text: "Leads", id: "leads" },
      tabs: [
        {
          category: "Causal Insights",
        },
        // {
        //   category: "Future Planning",
        // },
        // {
        //   category: "ROI Summary",
        // },
      ],
      durationDropdown: [
        { text: "3 Month", id: "3" },
        { text: "6 Month", id: "6" },
        { text: "9 Month", id: "9" },
      ],
      marketDropdown: [
        { text: "Leads", id: "leads" },
        { text: "Sales", id: "sales" },
      ],
      overallSeries: [
        {
          name: "Overall",
          colorByPoint: true,
          data: [
            {
              name: "Internal",
              y: 23,
              sliced: true,
            },
            {
              name: "External",
              y: 77,
              sliced: true,
            },
          ],
        },
      ],
      color: [
        "#f77f00",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#08a0e9",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
      ],
      tvData: [
        {
          label: "Google Ads",
          value: 31,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 22,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 17,
          backgroundColor: "#148f3a",
        },
        {
          label: "Publishers",
          value: 15,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 10,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 5,
          backgroundColor: "#08a0e9",
        },
      ],
      printData: [],
      digitalData: [
        {
          label: "Google Ads",
          value: 31,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 22,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 17,
          backgroundColor: "#148f3a",
        },
        {
          label: "Publishers",
          value: 15,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 10,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 5,
          backgroundColor: "#08a0e9",
        },
      ],
      contentData: [
        {
          label: "Paid Media",
          value: 61,
          backgroundColor: "#85edff",
        },
        {
          label: "Owned Content",
          value: 39,
          backgroundColor: "#b5a9ff",
        },
      ],
      externalData: [
        {
          name: "Seasonality",
          y: 55,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id1",
        },
        {
          name: "Competition",
          y: 23,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "Consumer Sentiment",
          y: 12,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Dollar value",
          y: 10,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      internalData: [
        {
          name: "Media",
          y: 52,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Product",
          y: 22,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "Promotions",
          y: 12,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Distribution",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
        {
          name: "PR",
          y: 4,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id5",
        },
      ],
      contentExpandData: {
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
      },
      tvExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      twoLevelTVBarData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      twoLevelBarData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      digitalExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: 50,
              x2: 60,
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: 1,
              x2: 20,
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: 20,
              x2: 30,
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: 60,
              x2: 100,
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
      ],
      printExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      radioExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      oohExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      sponsorshipData: [],
      sponsorshipExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      radioData: [],
      aggData: [],
      oohData: [],
      multipleTitle: ["External", "Internal"],
    };
  },

  created() {
    // this.callApis();
    // this.getInvVsRoiData();
    this.getInternalData();
    this.getExternalData();
    this.getMediaData();
    this.lifeTimeData();
    this.getAttributionData();

    // this.getLevelOneInvsVsRoiData()
    // this.secondLevelData(
    //   "investRoiData",
    //   "investmentvsroi",
    //   "/b2c/in/",
    //   "allindia",
    //   "tvdropdown",
    //   "tvTitle",
    //   "invsroiExpandData"
    // );
  },
  methods: {
    onChangeChannels() {
      //to be run onchange channel names with default levelTwo of  campaignName
      aimoSolutionsServices
        .getAttributionLevelTwo(
          "attribution",
          this.subId,
          this.selectedMarket.id,
          this.attributionDataTitle,
          this.attributionTitle.id
        )
        .then((res) => {
          this.attributionByChannelExpandData.data[0].data = [];
          this.attributionByChannelExpandData.xAxis.categories = [];

          for (var i = 0; i < res.response.length; i++) {
            this.attributionByChannelExpandData.data[0].data.push(res.response[i].value);
            this.attributionByChannelExpandData.xAxis.categories.push(
              res.response[i].name
            );
          }
        });
    },
    getAttributionLevelTwo(e) {
      aimoSolutionsServices
        .getAttributionLevelTwo(
          "attribution",
          this.subId,
          this.selectedMarket.id,
          this.attributionDataTitle,
          e.text
        )
        .then((res) => {
          this.attributionByChannelExpandData.data[0].data = [];
          this.attributionByChannelExpandData.xAxis.categories = [];
          this.attributionByChannelExpandData.data[0].name = "";

          for (var i = 0; i < res.response.length; i++) {
            this.attributionByChannelExpandData.data[0].data.push(res.response[i].value);
            this.attributionByChannelExpandData.xAxis.categories.push(
              res.response[i].name
            );
            this.attributionByChannelExpandData.data[0].name = e.text;
          }
        });
    },
    getAttributionData() {
      aimoSolutionsServices
        .getAttributionbyChannelData("attribution", this.selectedMarket.id, this.subId)
        .then((res) => {
          var res_data = res.response;
          this.attributionChannelWise.data[0].data = [];
          this.attributionChannelWise.xAxis.categories = [];
          this.tvdropdown = [
            { id: "CampaignName", text: "CampaignName" },
            { id: "Objective", text: "Objective" },
            { id: "Source", text: "Source" },
          ];
          this.tvTitle = this.tvdropdown[0];
          for (var i = 0; i < res_data.length; i++) {
            this.attributionChannelWise.data[0].data.push(res_data[i].value);
            this.attributionChannelWise.xAxis.categories.push(res_data[i].name);
          }
        });
    },

    lifeTimeData() {
      aimoSolutionsServices
        .getLifetimeChartData("lifetime", this.subId, this.selectedMarket.id)
        .then((res) => {
          var res_data = res.response;
          this.threeYearData = [];
          for (var i = 0; i < res_data.length; i++) {
            this.threeYearData.push({
              name: res_data[i].name,
              value: res_data[i].value,
            });
          }
        });
    },

    clickedAttribution(data) {
      this.attributionDataTitle = data.text;

      this.onChangeChannels();

      switch (data.text) {
        case "Channel1":
          this.showBreakdown = true;
          break;
        case "Channel2":
          this.showBreakdown = false;
          break;
        case "Channel3":
          this.showBreakdown = true;
          break;
        case "Channel4":
          this.showBreakdown = false;
          break;
        case "Channel5":
          this.showBreakdown = true;
          break;
        default:
          this.showBreakdown = false;
      }
    },

    clickedTv(data) {
      this.tvDataTitle = data.text;

      switch (data.text) {
        case "Digital":
          this.showBreakdown = true;
          break;
        case "Digital_Performance":
          this.showBreakdown = true;
          break;
        case "TV":
          this.showBreakdown = true;
          break;
        case "Print":
          this.showBreakdown = true;
          break;
        default:
          this.showBreakdown = false;
      }

      aimoSolutionsServices
        .getInvsVsRevenueData(
          "investmentvsrevenue",
          this.subId,
          "Digital",
          "CampaignType"
        )
        .then((res) => {
          const selectedChannel = res.response.response.levelTwo.filter((item) =>
            item.name.includes(data.text)
          );

          this.invsroiExpandData.data[0].data = [];
          this.invsroiExpandData.data[1].data = [];
          this.invsroiExpandData.xAxis.categories = [];

          for (let i = 0; i < selectedChannel[0].value.length; i++) {
            this.invsroiExpandData.data[0].data.push(
              selectedChannel[0].value[i].Investment
            );
            this.invsroiExpandData.data[1].data.push(selectedChannel[0].value[i].ROI);

            this.invsroiExpandData.xAxis.categories.push(
              selectedChannel[0].value[i].name
            );
          }
        });
    },
    getLevelThreeData(dataVariable, param, inst, market, extraText, removeSpace) {
      var param1 = param;
      if (removeSpace) {
        param1 = param.replace(/\s/g, "");
      }
      if (param1 === "googleads") {
        param1 = "goodgleads";
      } else if (param1 === "programmatic") {
        param1 = "programatics";
      } else if (param1 === "broadcast tv") {
        param1 = "broadcast";
      } else if (param1 === "paid media") {
        param1 = "paid";
      } else if (param1 === "owned content") {
        param1 = "owned";
      } else if (param1 === "magazines") {
        param1 = "magazine";
      } else if (param1 === "atlanta") {
        param1 = "atalanta";
      } else if (param1 === "sports") {
        param1 = "sports games";
      } else if (param1 === "pos displays") {
        param1 = "pos display";
      } else if (param1 === "andhra pradesh") {
        param1 = "ap";
      } else if (param1 === "malayalam") {
        param1 = "malyalam";
      } else if (param1 === "gujarathi") {
        param1 = "gujrati";
      } else if (param1 === "bangalore") {
        param1 = "banagalore";
      } else if (param1 === "tv") {
        param1 = "tvroiinvestmentvsroi";
      } else if (param1 === "aggregators") {
        param1 = "aggregatorsroiinvestmentvsroi";
      } else if (param1 === "digital") {
        param1 = "digitalroiinvestmentvsroi";
      } else if (param1 === "radio") {
        param1 = "radioroiinvestmentvsroi";
      } else if (param1 === "print") {
        param1 = "printroiinvestmentvsroi";
      }
      aimoSolutionsServices
        .getChartData("roi summary", inst, extraText + param1, market)
        .then((res) => {
          this[dataVariable].data[0].data = [];
          this[dataVariable].data[1].data = [];
          this[dataVariable].xAxis.categories = [];
          if (
            dataVariable === "digitalMedia" ||
            dataVariable === "contentMedia" ||
            dataVariable === "invsroiExpandData" ||
            dataVariable === "printMedia" ||
            dataVariable === "radioMedia"
          ) {
            for (var i = 0; i < res.length; i++) {
              this[dataVariable].data[0].data.push(res[i].Investment);
              this[dataVariable].data[1].data.push(res[i].ROI);
              this[dataVariable].xAxis.categories.push(res[i].name);
            }
          } else {
            for (i = 0; i < res.length; i++) {
              this[dataVariable].data[0].data.push(res[i].Investment);
              this[dataVariable].data[1].data.push(res[i].ROI);
              this[dataVariable].xAxis.categories.push(res[i].name);
            }
          }
        });
    },
    selectedTvDropdown(e) {
      if (e === this.tvTitle || e === this.tvTitle.text) {
        return;
      }

      aimoSolutionsServices
        .getInvsVsRevenueData("investmentvsrevenue", this.subId, this.tvDataTitle, e.text)
        .then((res) => {
          this.invsroiExpandData.data[0].data = [];
          this.invsroiExpandData.data[1].data = [];
          this.invsroiExpandData.xAxis.categories = [];

          for (var i = 0; i < res.response.response.levelTwo.length; i++) {
            this.invsroiExpandData.data[0].data.push(
              res.response.response.levelTwo[i].value[i].Investment
            );
            this.invsroiExpandData.data[1].data.push(
              res.response.response.levelTwo[i].value[i].ROI
            );
            this.invsroiExpandData.xAxis.categories.push(
              res.response.response.levelTwo[i].value[i].name
            );
          }
        });
      this.tvTitle = e;
    },

    selectedAttributionDropdown(e) {
      if (e.text === "CampaignName") {
        this.getAttributionLevelTwo(e);
      }

      if (e === this.attributionTitle || e === this.attributionTitle.text) {
        return;
      }

      this.getAttributionLevelTwo(e);

      this.attributionTitle = e;
    },

    getFirstLevelData(dataVariable, param) {
      var color = "";
      if (param === "content") {
        color = "rgb(251,140,0)";
      } else if (param === "tv") {
        color = "rgb(216,27,96)";
      } else if (param === "print") {
        color = "rgb(94,53,177)";
      } else if (param === "radio") {
        color = "rgb(167,19,19)";
      } else if (param === "aggregator") {
        color = "rgb(30,136,229)";
      } else if (param === "digital") {
        color = "rgb(106,27,154)";
      } else {
        color = "rgb(247,127,0)";
      }
      aimoSolutionsServices
        .getChartData("sales causality", "/b2c/in/", param, this.selectedMarket.id)
        .then((res) => {
          // var opacity = 1;
          this[dataVariable] = [];
          for (var i = 0; i < res.length; i++) {
            var eachOpacity;
            if (i === 0) {
              eachOpacity = 1;
            } else {
              if (i > 10) {
                eachOpacity = 0.5;
              } else {
                eachOpacity = (100 - i * 10) / 100;
              }
            }
            this[dataVariable].push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: this.getColor(color, eachOpacity),
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getDigitalBreakdown(name) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          name.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.digitalExpandData = [];
          var count = 0;
          for (var i = 0; i < res.length; i++) {
            this.digitalExpandData.push({
              name: res[i].name,
              pointWidth: 20,
              data: [
                {
                  x: count,
                  x2: count + res[i].value,
                  y: i + 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            });
            count += res[i].value;
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getStripBreakdown(data) {
      var value = this.twoLevelStripData.secondStripData.find(
        (obj) => obj.name === data.label
      ).value;
      this.secondStripData = value;
    },
    getTVStripBreakdown(data) {
      var value = this.twoLevelTVStripData.secondStripData.find(
        (obj) => obj.name === data.label
      ).value;
      this.secondTVStripData = value;
    },
    getTVBarDataBreakdown(e) {
      this.twoLevelTVBarData.series = [];
      for (var i = 0; i < e.barData.length; i++) {
        this.twoLevelTVBarData.series.push({
          name: e.barData[i].name,
          data: [e.barData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
      }
    },
    getBarDataBreakdown(e) {
      this.twoLevelBarData.series = [];
      for (var i = 0; i < e.barData.length; i++) {
        this.twoLevelBarData.series.push({
          name: e.barData[i].name,
          data: [e.barData[i].value],
          pointWidth: 16,
          yAxis: 1,
        });
      }
    },
    getPrintBreakdown(data) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.printExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.printExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getRadioBreakdown(data) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.radioExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.radioExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getOOHBreakdown(data) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.oohExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.oohExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getSponsorshipBreakdown(data) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.sponsorshipExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.sponsorshipExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getContentBreakdown(data) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.contentExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.contentExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    // getBillboardBreakdown(data) {
    //   aimoSolutionsServices
    //     .getChartData(
    //       "sales causality",
    //       "/b2c/in/",
    //       data.label.toLowerCase(),
    //       this.selectedMarket.id
    //     )
    //     .then((res) => {
    //       this.billboardExpandData.series = [];
    //       for (var i = 0; i < res.length; i++) {
    //         this.billboardExpandData.series.push({
    //           name: res[i].name,
    //           data: [res[i].value],
    //           pointWidth: 16,
    //           yAxis: 1,
    //         });
    //       }
    //       this.count++;
    //     })
    //     .catch(() => {
    //       this.count++;
    //     });
    // },
    getTvExpandData(name) {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          name.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.tvExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.tvExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    gettvBreakdown(data) {
      this.getTvExpandData(data.label);
    },
    getExternalData() {
      aimoSolutionsServices
        .getExternalChartData("core", this.subId, this.selectedMarket.id)
        .then((res) => {
          var data = res.response;
          this.regionWiseData.data[0].data = [];
          this.regionWiseData.xAxis.categories = [];
          for (var i = 0; i < data.length; i++) {
            this.regionWiseData.data[0].data.push(data[i].value);
            this.regionWiseData.xAxis.categories.push(data[i].name);
          }
        });
    },
    getInternalExternalData() {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          "internalandexternal",
          this.selectedMarket.id
        )
        .then((res) => {
          this.externalInternalWiseData.data[0].data = [];
          this.externalInternalWiseData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.externalInternalWiseData.data[0].data.push(res[i].value);
            this.externalInternalWiseData.xAxis.categories.push(res[i].name);
          }
        });
    },
    getLagEffectDataData() {
      aimoSolutionsServices
        .getChartData("sales causality", "/b2c/in/", "legeffect", this.selectedMarket.id)
        .then((res) => {
          this.lineChartData.series[0].data = [];
          this.lineChartData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.lineChartData.series[0].data.push(res[i].value);
            this.lineChartData.xAxis.categories.push(res[i].name);
          }
        });
    },

    getInternalData() {
      aimoSolutionsServices
        .getInternalChartData("media", this.subId, this.selectedMarket.id)
        .then((res) => {
          var data = res.response;
          this.internalColumnData.data[0].data = [];
          this.internalColumnData.xAxis.categories = [];
          for (var i = 0; i < data.length; i++) {
            this.internalColumnData.data[0].data.push(data[i].value);
            this.internalColumnData.xAxis.categories.push(data[i].name);
          }
        })
        .catch(() => {
          this.count++;
        });
    },
    getMediaData() {
      aimoSolutionsServices
        .getMediaChartData("media", this.subId, this.selectedMarket.id)
        .then((res) => {
          // console.log(res.response)
          var data = res.response;
          this.mediaChartData.data[0].data = [];
          this.mediaChartData.xAxis.categories = [];
          for (var i = 0; i < data.length; i++) {
            this.mediaChartData.data[0].data.push(data[i].value);
            this.mediaChartData.xAxis.categories.push(data[i].name);
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getQuartlyAttribution() {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          "attributionbyquarter",
          this.selectedMarket.id
        )
        .then((res) => {
          this.quartlyAttribution.data[0].data = [];
          this.quartlyAttribution.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.quartlyAttribution.data[0].data.push(res[i].value);
            this.quartlyAttribution.xAxis.categories.push(res[i].name);
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getOverallAttribution() {
      aimoSolutionsServices
        .getChartData(
          "sales causality",
          "/b2c/in/",
          "attributionoveraperiodoftime",
          this.selectedMarket.id
        )
        .then((res) => {
          this.stackChartData.data = [];
          this.stackChartData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.stackChartData.xAxis.categories.push(res[i].name);
            for (var j = 0; j < res[i].Value.length; j++) {
              var dataFind = this.stackChartData.data.find(
                (x) => x.name === res[i].Value[j].name
              );
              if (dataFind !== undefined) {
                var value = Math.round(res[i].Value[j].value * 100) / 100;
                dataFind.data.push(value);
              } else {
                value = Math.round(res[i].Value[j].value * 100) / 100;
                this.stackChartData.data.push({
                  name: res[i].Value[j].name,
                  data: [value],
                });
              }
            }
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getDigitalData() {
      aimoSolutionsServices
        .getChartData("sales causality", "/b2c/in/", "digital", this.selectedMarket.id)
        .then((res) => {
          this.twoLevelStripData.title = res.title;
          this.twoLevelStripData.secondStripData = [];
          this.twoLevelStripData.stripData = [];
          for (var i = 0; i < res.stripData.length; i++) {
            this.twoLevelStripData.stripData.push({
              label: res.stripData[i].name,
              value: res.stripData[i].value,
              backgroundColor: this.color[i],
            });
          }
          for (var j = 0; j < res.secondStripData.length; j++) {
            var data = [];
            for (var k = 0; k < res.secondStripData[j].value.length; k++) {
              data.push({
                label: res.secondStripData[j].value[k].name,
                value: res.secondStripData[j].value[k].value,
                backgroundColor: this.color[k],
                barData: res.secondStripData[j].value[k].finalLayerData,
              });
            }
            this.twoLevelStripData.secondStripData.push({
              name: res.secondStripData[j].name,
              value: data,
            });
          }
        });
    },
    getTVData() {
      aimoSolutionsServices
        .getChartData("sales causality", "/b2c/in/", "tv", this.selectedMarket.id)
        .then((res) => {
          this.twoLevelTVStripData.title = res.title;
          this.twoLevelTVStripData.secondStripData = [];
          this.twoLevelTVStripData.stripData = [];
          for (var i = 0; i < res.stripData.length; i++) {
            this.twoLevelTVStripData.stripData.push({
              label: res.stripData[i].name,
              value: res.stripData[i].value,
              backgroundColor: this.color[i],
            });
          }
          for (var j = 0; j < res.secondStripData.length; j++) {
            var data = [];
            for (var k = 0; k < res.secondStripData[j].value.length; k++) {
              data.push({
                label: res.secondStripData[j].value[k].name,
                value: res.secondStripData[j].value[k].value,
                backgroundColor: this.color[k],
                barData: res.secondStripData[j].value[k].finalLayerData,
              });
            }
            this.twoLevelTVStripData.secondStripData.push({
              name: res.secondStripData[j].name,
              value: data,
            });
          }
        });
    },
    getOverallChartData() {
      aimoSolutionsServices
        .getChartData("sales causality", "/b2c/in/", "overall", this.selectedMarket.id)
        .then((res) => {
          this.overAllData.data[0].data = [];
          this.overAllData.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.overAllData.data[0].data.push(res[i].value);
            this.overAllData.xAxis.categories.push(res[i].name);
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    getBreakdown(newVal) {
      this.breakDown = newVal;
    },
    selectDuration(e) {
      this.selectedDuration = e;
    },
    selectMarket(e) {
      var defaultOption = JSON.parse(sessionStorage.getItem("solutionsKpiMarket"));
      if (e === "Select") {
        if (e === this.selectedMarket) {
          return;
        }
        this.selectedMarket = defaultOption;
        return;
      }
      if (e.id === this.selectedMarket.id) {
        return;
      }
      sessionStorage.setItem("solutionsKpiMarket", JSON.stringify(e));

      this.selectedMarket = e;
      // this.callApis();
      //  this.getInvVsRoiData();
      this.getInternalData();
      this.getExternalData();
      this.getMediaData();
      this.lifeTimeData();
      this.populateTabs();
      this.getAttributionData()
    },

    populateTabs() {
      this.tabs = [];
      switch (this.selectedMarket.id) {
        case "leads":
          this.tabs.push({
            category: "Causal Insights",
          });
          break;
        case "sales":
          this.tabs.push({ category: "Causal Insights" }, { category: "ROI Summary" });
          break;
        default:
          this.tabs.push({
            category: "Causal Insights",
          });
      }
    },
  },
  computed: {
    getColor() {
      return (color, opacity) => {
        var x = color.split(")");
        return x[0] + "," + opacity + ")";
      };
    },
  },
};
</script>

<style scoped>
.card-heading {
  margin: 20px 20px 0px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
  padding-left: 15px;
  padding-top: 15px;
}

.breakdownable-card {
  border-left: 2px solid #d4dae4;
  position: relative;
}

.breakdownable-card::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  z-index: 9999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.breakdownable-card::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #d4dae4;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.item {
  margin-top: 20px;
}

.title {
  font-family: ProximaNovaRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
  margin-bottom: 5px;
}

::v-deep .progress-bar {
  text-align: end;
  padding-right: 6px;
}
</style>
