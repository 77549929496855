<template>
  <b-row>
    <b-col cols="12">
      <StripChart :chartData="stripData" @getBreakdown="breakdown" @getDataBreakdown="getDataBreakdown"
        :breakdown="breakDownBoolean" :showBreakdown="showBreakdownIcon" :title="title" :subtitle="subtitle"
        :total="total" :activePart="selectedData.label" :dot="dot" :showLegend="showLegend" />
    </b-col>
    <b-col cols="12" v-if="breakDownBoolean">
      <h3 class="mt-5 ml-2">
        <strong>{{ customTwoLevelLabel }}</strong>
      </h3>
      <StripChart :chartData="secondStripData" @getBreakdown="getBarBreakdown" @getDataBreakdown="getBarDataBreakdown"
        :breakdown="barBreakdown" :showBreakdown="secondShowBreakdownIcon" :title="''" :subtitle="subtitle"
        :total="total" :activePart="selctedForBarData.label" :dot="dot" :showLegend="showLegend" />
    </b-col>
    <b-col cols="12" v-if="breakDownBooleanThird">
      <h3 class="mt-5 ml-2">
        <strong>{{ customThirdLevelLabel }}</strong>
      </h3>
      <StripChart :chartData="thirdStripData" @getBreakdown="getThirdBarBreakdown"
        @getDataBreakdown="getThirdBarDataBreakdown" :breakdown="barBreakdown" :showBreakdown="false" :title="''"
        :subtitle="subtitle" :total="total" :activePart="selctedForThirdBarData.label" :dot="dot"
        :showLegend="showLegend" />
    </b-col>
    <b-col cols="12" v-if="
      breakDownBoolean &&
      showBreakdownIcon &&
      barBreakdown &&
      barData.series.length !== 0
    " class="horizontal-breakdownable-card px-0"
      :style="{ '--left': selctedForBarData.value / 2 + barvalueAdded + '%' }">
      <bar-chart :chartData="barData" />
    </b-col>
  </b-row>
</template>
<script>
import StripChart from "@/components/Chart/StripChart.vue";
import BarChart from "@/components/Chart/BarChart.vue";
export default {
  name: "TwoLevelChart",
  beforeUpdate() {
    this.barChartData = this.data;
  },
  created() {
    this.initalCall();
  },
  components: {
    StripChart,
    BarChart,
  },
  props: {
    secondStripData: {
      type: Array,
      default: () => [],
    },
    thirdStripData: {
      type: Array,
      default: () => [],
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    customTwoLevelLabel: {
      type: String,
      default: "",
    },
    customThirdLevelLabel: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Content",
    },
    breakeDownShow: {
      type: Boolean,
      default: false,
    },
    showBreakdownIcon: {
      type: Boolean,
      default: true,
    },
    secondShowBreakdownIcon: {
      type: Boolean,
      default: false,
    },
    barData: {
      type: Object,
      default: () => { },
    },
    stripData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    dot: {
      type: String,
      default: "",
    },
    symbol: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      breakDownBoolean: false,
      selectedData: "",
      selctedForBarData: "",
      selctedForThirdBarData: "",
      valueAdded: 0,
      barvalueAdded: 0,
      thirdBarvalueAdded: 0,
      barBreakdown: false,
      breakDownBooleanThird: false
    };
  },
  methods: {
    findIndexPosition(data) {
      this.valueAdded = 0;
      let ind = this.stripData.findIndex((obj) => obj.label === data.label);
      if (ind > 0) {
        for (var i = 0; i < ind; i++) {
          var value = this.stripData[i].value;
          this.valueAdded += value;
        }
      } else {
        this.valueAdded = 0;
      }
    },
    findIndexPositionBar(data) {
      this.barvalueAdded = 0;
      let ind = this.secondStripData.findIndex((obj) => obj.label === data.label);
      if (ind > 0) {
        for (var i = 0; i < ind; i++) {
          var value = this.secondStripData[i].value;
          this.barvalueAdded += value;
        }
      } else {
        this.barvalueAdded = 0;
      }
    },
    findIndexPositionThirdBar(data) {
      this.thirdBarvalueAdded = 0;
      let ind = this.thirdStripData.findIndex((obj) => obj.label === data.label);
      if (ind > 0) {
        for (var i = 0; i < ind; i++) {
          var value = this.thirdStripData[i].value;
          this.thirdBarvalueAdded += value;
        }
      } else {
        this.thirdBarvalueAdded = 0;
      }
    },
    getDataBreakdown(data) {
      this.selectedData = data.data;
      this.findIndexPosition(data.data);
      this.breakdown(data.breakdown);
      this.$emit("getDataBreakdown", data.data);
    },
    getBarDataBreakdown(data) {
      this.selctedForBarData = data.data;
      this.findIndexPositionBar(data.data);
      this.getBarBreakdown(data.breakdown);
      this.$emit("getBarDataBreakdown", data.data);
    },
    getThirdBarDataBreakdown(data) {
      this.selctedForThirdBarData = data.data;
      this.findIndexPositionThirdBar(data.data);
    },
    breakdown(newVal) {
      this.breakDownBoolean = newVal;
      this.barBreakdown = false;
    },
    getBarBreakdown(e) {
      this.breakDownBooleanThird = e;
      this.barBreakdown = e;
    },
    initalCall() {
      this.barChartData = this.data;
      this.breakDownBoolean = this.breakeDownShow;
    },
  },
};
</script>

<style scoped>
.horizontal-breakdownable-card {
  border-top: 2px solid #d4dae4;
  position: relative;
}

.horizontal-breakdownable-card::before {
  content: "";
  width: 40px;
  height: 20px;
  position: absolute;
  background: #fff;
  top: -17px;
  transform: translateX(-50%);
  left: var(--left);
  z-index: 9999;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.horizontal-breakdownable-card::after {
  content: "";
  width: 40px;
  height: 20px;
  position: absolute;
  background: #d4dae4;
  top: -20px;
  transform: translateX(-50%);
  left: var(--left);
  z-index: 999;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
</style>
